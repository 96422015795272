import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'Raphael-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss']
})
export class PopupMenuComponent implements OnInit {
  @Output() closeMenu=new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }
  scroll(id:string ) {
    this.closeMenu.emit(false);
     let el = <HTMLElement>document.getElementById(id);
     window.scrollTo({left:el.offsetLeft,top:el.offsetTop-160,
      behavior: 'smooth'});
    }
}
