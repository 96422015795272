import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './components/layout/layout.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ErrorComponent } from './components/error/error.component';
import { PopupMenuComponent } from './components/popup-menu/popup-menu.component';



@NgModule({
  declarations: [LayoutComponent, HeaderComponent, FooterComponent, LoaderComponent, ErrorComponent, PopupMenuComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[LoaderComponent]
})
export class SharedModule { }
