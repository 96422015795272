<div class="close" (click)="closeMenu.emit(false)">
  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 329.26933 329"  ><g fill="#3e85c1"><path d="m21.339844 329.398438c-5.460938 0-10.925782-2.089844-15.082032-6.25-8.34375-8.339844-8.34375-21.824219 0-30.164063l286.589844-286.59375c8.339844-8.339844 21.824219-8.339844 30.164063 0 8.34375 8.339844 8.34375 21.824219 0 30.164063l-286.589844 286.59375c-4.183594 4.179687-9.621094 6.25-15.082031 6.25zm0 0"/><path d="m307.929688 329.398438c-5.460938 0-10.921876-2.089844-15.082032-6.25l-286.589844-286.59375c-8.34375-8.339844-8.34375-21.824219 0-30.164063 8.339844-8.339844 21.820313-8.339844 30.164063 0l286.589844 286.59375c8.34375 8.339844 8.34375 21.824219 0 30.164063-4.160157 4.179687-9.621094 6.25-15.082031 6.25zm0 0"/></g></svg>
</div>

<ul>
  <li (click)="scroll('home')">	About us</li>
  <li (click)="scroll('services')">our Services</li>
  <li (click)="scroll('staff')">our staff</li>
  <li (click)="scroll('contact')">Contact us</li>
  <li (click)="scroll('getIntouch')">Get In Touch</li>

</ul>
