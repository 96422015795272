import { LayoutComponent } from './modules/shared/components/layout/layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [

  {
    path:'',
   component: LayoutComponent,
   children:[
     {
     path:'',
     loadChildren:()=>import("./modules/home/home.module").then(res=>res.HomeModule)
     },
     {
      path:'home',
      loadChildren:()=>import("./modules/home/home.module").then(res=>res.HomeModule)
    }


  ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
